(function(window){
    function ProductFilterPager(filter, options) {
        try {
            if (!filter) {
                throw 'Filter obj is required!';
            }

            this.filter = filter;
            if (options) {
                this.setParams(options);
            }

            this.init();
        } catch (error) {
            console.error(error);
        }
    }

    ProductFilterPager.prototype = {
        filter: undefined,
        show_first_page: false,
        show_last_page: false,
        wrap: 1,
        per_page: 20,
        current_page: 1,
        total: 0,
        ajax:true,
        page_number: 0,
        content: undefined,
        arrow_type: 'icon',
        next_arrow_icon: 'arrow-right',
        last_arrow_icon: 'double-arrow-right',
        texts: {},
        need_dots: true,
        button_class: 'button',
        init: function() {
            this.setPageNumber();
            this.addDOMEvents();
            
            if (this.getPageNumber() > 1) {
                this.createContent();
                this.addPagerDOMEvents();
                this.writeHtml();
            }
        },
        addDOMEvents: function() {
            var _self = this;

            $(document).on('shoppingstreet.category_page_content_changed', function(e) {
                _self.reload();
            });
        },
        addPagerDOMEvents: function() {
            var _self = this;
            if (this.content) {
                this.content.find('.pager-item').click(function(e){
                    if (_self.isAjax()) {
                        e.preventDefault();
                    }

                    var page = $(this).data('value');
                    if (page == _self.getCurrentPage()) {
                        return false;
                    }

                    $(document).trigger('shoppingstreet.pager_value_changed', page);
                });
            }
        },
        setParams: function(params) {
            if (params.perPage) this.setPerPage(params.perPage);
            if (params.showFirstPage) this.setShowFirstPage(params.showFirstPage);
            if (params.showLastPage) this.setShowLastPage(params.showLastPage);
            if (params.currentPage) this.setCurrentPage(params.currentPage);
            if (params.total) this.setTotal(params.total);
            if (typeof params.ajax !== 'undefined') this.setAjax(params.ajax);
            if (params.arrowType) this.setArrowType(params.arrowType);
            if (params.nextArrowIcon) this.setNextArrowIcon(params.nextArrowIcon);
            if (params.lastArrowIcon) this.setLastArrowIcon(params.lastArrowIcon);
            if (params.texts) this.setTexts(params.texts);
            if (params.wrap) this.setWrap(params.wrap);
            if (typeof params.need_dots !== 'undefined') this.setNeedDots(params.need_dots);
            if (params.buttonClass) this.setButtonClass(params.buttonClass);
        },
        setButtonClass: function(button_class) {
            this.button_class = button_class;
        },
        getButtonClass: function() {
            return this.button_class;
        },
        setAjax: function(ajax) {
            this.ajax = ajax;
        },
        setPerPage: function(perpage) {
            this.per_page = perpage;
        },
        setWrap: function(wrap) {
            this.wrap = wrap;
        },
        setCurrentPage: function(currentPage) {
            this.current_page = currentPage;
        },
        setShowFirstPage: function(show) {
            this.show_first_page = show;
        },
        setShowLastPage: function(show) {
            this.show_last_page = show;
        },
        setTotal: function(total) {
            this.total = total;
        },
        setPageNumber: function() {
            this.page_number = Math.ceil(this.getTotal() / this.getPerPage());
        },
        setNextArrowIcon: function(icon) {
            this.next_arrow_icon = icon;
        },
        setLastArrowIcon: function(icon) {
            this.last_arrow_icon = icon;
        },
        setArrowType: function(type) {
            this.arrow_type = type;
        },
        setTexts: function(texts) {
            this.texts = $.extend({}, this.texts, texts);
        },
        setNeedDots: function(dots) {
            this.need_dots = dots;
        },
        getPageUrl: function(page) {
            return 'develop';
        },
        getTotal: function() {
            return this.filter.getOption('total');
        },
        getPerPage: function() {
            return this.per_page;
        },
        getCurrentPage: function() {
            return this.filter.getCurrentPage();
        },
        getPageNumber: function() {
            return this.page_number;
        },
        isFirstPageVisible: function() {
            return this.show_first_page;
        },
        isLastPageVisible: function() {
            return this.show_last_page;
        },
        isAjax: function() {
            return this.ajax;
        },
        getText: function(key) {
            return typeof this.texts[key] !== 'undefined' ? this.texts[key] : key;
        },
        getNextArrowIcon: function() {
            return this.next_arrow_icon;
        },
        getLastArrowIcon: function() {
            return this.last_arrow_icon;
        },
        getArrowType: function() {
            return this.arrow_type;
        },
        getWrap: function() {
            return this.wrap;
        },
        needDots: function() {
            return this.need_dots;
        },
        createContent: function() {
            var total = this.getPageNumber();
            if (total <= 1) {
                return;
            }

            this.content = $('<div>', {
                class:'pager-inner'
            });
            var current = this.getCurrentPage();
            var first = current - this.getWrap();
            var last = current + this.getWrap();
            
            if (first <= 1) {
                first = this.isFirstPageVisible() ? 2 : 1;
            }
            
            if (last >= total) {
                last = this.isLastPageVisible() ? total - 1 : total;
            }
            
            this.createNavigationButtons('prev');
            var container = $('<span>', {
                class: 'pager-items'
            });

            if (this.isFirstPageVisible()) {
                container.append($('<a>', {
                    href: this.isAjax() ? 'javascript:void(0)' : this.getPageUrl(i),
                    class:'pager-item '+(current == 1 ? 'selected' : ''),
                    'data-value':1,
                    html:'<span>'+1+'</span>'
                }));
            }

            if (this.needDots() && first != (this.isFirstPageVisible() ? 2 : 1)) {
                container.append('<span class="pager-dots dots prev-dots">...</span>');
            }

            container.append('<span class="page-counter"><span>'+current + ' / ' + total+'</span></span>')
            
            for (var i = first; i <= last; i++) {
                container.append($('<a>', {
                    href: this.isAjax() ? 'javascript:void(0)' : this.getPageUrl(i),
                    class:'pager-item '+(i == current ? 'selected' : ''),
                    'data-value':i,
                    html:'<span>'+i+'</span>'
                }));
            }
            
            if (this.needDots() && (total - last) > (this.isLastPageVisible() ? 1 : 0)) {
                container.append('<span class="pager-dots dots next-dots">...</span>');
            }

            if (this.isLastPageVisible()) {
                container.append($('<a>', {
                    href: this.isAjax() ? 'javascript:void(0)' : this.getPageUrl(i),
                    class:'pager-item '+(current == total ? 'selected' : ''),
                    'data-value':total,
                    html:'<span>'+total+'</span>'
                }));
            }

            this.content.append(container);
            this.createNavigationButtons('next');
        },
        createNavigationButtons: function(type) {
            var container = $('<span>', {
                class:'navigation-buttons-outer '+type
            });
            
            var page = type == 'prev' ? this.getCurrentPage() - 1 : this.getCurrentPage() + 1;
            var last_page = type == 'prev' ? 1 : this.getPageNumber();
            
            if (page < 1) {
                page = 1;
            } else if (page > this.getPageNumber()) {
                page = this.getPageNumber();
            }

            var item = $('<a>', {
                'href': this.isAjax() ? 'javascript:void(0)' : this.getPageUrl(page),
                'data-value':page,
                'class': 'pager-item navigation-button '+type+'-button',
            });

            var last = $('<a>', {
                'href': this.isAjax() ? 'javascript:void(0)' : this.getPageUrl(page),
                'data-value':last_page,
                'class': 'pager-item navigation-button '+type+'-jump-button',
            });

            switch (this.getArrowType()) {
                case 'icon':
                    item.html(svg(this.getNextArrowIcon()));    
                    last.html(svg(this.getLastArrowIcon()));
                    break;
                case 'hibrid':
                    item.addClass(this.getButtonClass());
                    last.addClass(this.getButtonClass());
                    
                    if (type == 'prev') {
                        item.append(svg(this.getNextArrowIcon()));    
                        last.append(svg(this.getLastArrowIcon()));
                    } 

                    item.append('<span class="text">'+this.getText(type+'_btn_txt')+'</span>');
                    last.append('<span class="text">'+this.getText(type+'_last_btn_txt')+'</span>');

                    if (type == 'next') {
                        item.append(svg(this.getNextArrowIcon()));    
                        last.append(svg(this.getLastArrowIcon()));
                    }
                    break;
                default:
                    item.addClass(this.getButtonClass());
                    item.html(this.getText(type+'_btn_txt'));
                    
                    last.addClass(this.getButtonClass());
                    last.html(this.getText(type+'_last_btn_txt'));
                    break;

            }

            if ((type == 'prev' && this.getCurrentPage() == 1) || (type == 'next' && this.getPageNumber() == this.getCurrentPage())) {
                item.addClass('inactive');
                last.addClass('inactive');
            } 


            if (type == 'prev') {
                if (!this.isFirstPageVisible()) {
                    container.append(last);
                }
                container.append(item);
            } else {
                container.append(item);
                if (!this.isLastPageVisible()) {
                    container.append(last);
                }
            }
            
            this.content.append(container);
        },
        writeHtml: function() {
            if (this.content) {
                this.filter.pagerArea.append(this.content);
            } else {
                this.filter.pagerArea.html('');
            }
        },
        reload: function() {
            if (this.content) {
                this.content.find('.pager-item').off('click');
                this.content.html('');
                this.content.remove();
            }

            this.setPageNumber();
            
            this.setCurrentPage(this.filter.getCurrentPage());
            this.createContent();
            this.addPagerDOMEvents();
            this.writeHtml();
        }
    };

    window.ProductFilterPager = ProductFilterPager;
})(window);
