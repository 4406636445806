function FilterTagger(filter, params) {
    this.filter = filter;

    if (params) {
        this.setParams(params);
    }

    this.init();
}

FilterTagger.prototype = {
    filter: undefined,
    container: undefined,
    init: function() {
        this.setElements();
        this.addDOMEvents();
    },
    setElements: function() {
        this.container = $('[data-purpose="filter-tags-container"]');
    },
    addDOMEvents: function() {
        var _self = this;

        $(document).on('shoppingstreet.filter_state_changed', function(e, datas){
            _self.checkTags(datas);
        });

        $(document).on('shoppingstreet.filter_reset', function(){
            _self.container.html('');
        });
    },
    setParams: function(params) {
        console.log(params);
    },
    checkTags: function(datas) {
        if (datas.values.length > 0) {
            switch (datas.type) {
                case 'checkbox':
                    this.checkCheckboxTags(datas);
                    break;
                case 'slider':
                    this.checkRangeTags(datas);
                    break;
            }
            
        } else {
            this.container.find('.filter-tag[data-key="'+datas.key+'"]').remove();
        }

        var tags = this.container.find('.filter-tag');
        if (tags.length == 0 && this.container.hasClass('has-tags')) {
            this.container.removeClass('has-tags');
        } else if (tags.length > 0 && !this.container.hasClass('has-tags')) {
            this.container.addClass('has-tags');
        }
    },
    checkCheckboxTags: function(datas) {
        var exists = [];
        this.container.find('.filter-tag[data-key="'+datas.key+'"]').each(function(){
            exists.push(String($(this).data('value')));
        });
        
        for (var i = 0; i < datas.values.length; i++) {
            var value = datas.values[i];
            if (exists.indexOf(String(value)) < 0) {
                var input = this.filter.container.find('input[data-key="'+datas.key+'"][value="'+value+'"]');
                if (input.length > 0) {
                    this.createTag(datas.key, input.data('label'), String(value));
                    exists.push(String(value));
                }
            }
        }
        
        if (exists.length !== datas.values.length) {
            for (var i = 0; i < exists.length; i++) {
                var value = exists[i];
                
                if (datas.values.indexOf(String(value)) < 0) {
                    var tag = this.container.find('.filter-tag[data-key="'+datas.key+'"][data-value="'+value+'"]');
                    if (tag.length > 0) {
                        tag.remove();
                    }
                }
            }
        }
    },
    checkRangeTags: function(datas) {
        var tag = this.container.find('.filter-tag[data-key="'+datas.key+'"]');
        var label = datas.values[0] +' - '+ datas.values[1];
        
        if (tag.length) {
            tag.find('.text').html(label);
        } else {
            this.createTag(datas.key, label);
        }
    },
    createTag: function(key, label, value) {
        var _self = this;
        var tag = $('<span>', {
            'class':'filter-tag',
            'data-key':key,
            'html': '<span class="text">'+label+'</span>'+svg('tag-delete-icon')
        });

        if (value) {
            tag.attr('data-value', String(value));
        }

        this.container.append(tag);

        tag.find('.svg').click(function(){
            _self.removeTag(tag);
        });
    },
    removeTag: function(tag) {
        var key = tag.data('key');
        if (key) {
            var field = this.filter.getFieldValue(key);
            if (field) {
                switch (field.type) {
                    case 'slider':
                        field.values = [];
                        break;
                    case 'checkbox': 
                        var values = [];
                        this.container.find('.filter-tag[data-key="'+key+'"]').each(function(){
                            
                            if ($(this).data('value') != tag.data('value')) {
                                values.push(String($(this).data('value')));
                            }
                        });
                        field.values = values;
                        break;
                }

                this.filter.updateFieldValues(key, field);
            }
        }
    }
};
