function CheckboxFilterField(filter, params) {
    FilterField.apply(this, arguments);
}

CheckboxFilterField.prototype = $.extend({}, FilterField.prototype, {
    type:'checkbox',
    setState: function(initialize) {
        var values = [];
        if (this.inputs.length > 0) {
            this.inputs.each(function() {
                if ($(this).prop('checked')) {
                    values.push($(this).val());
                }
            });
        }
        
        this.state = values;
        if (!initialize) {
            this.filter.updateFieldValues(this.getKey(), this.serialize());
        } else if (values.length > 0) {
            this.filter.setFieldValues(this.getKey(), this.serialize());
        }
    },
    setChangedFieldValues: function(values) {
        this.fieldHtml.find('input[type="checkbox"]').each(function(){
            var value = $(this).val();
            if (values.indexOf(String(value)) < 0 && $(this).prop('checked')) {
                $(this).prop('checked', false);
            }
        });

        this.state = values;
    }
});
