function SliderFilterField(filter, params) {
    FilterField.apply(this, arguments);
}

SliderFilterField.prototype = $.extend({}, FilterField.prototype, {
    type: 'slider',
    setElements: function() {
        this.fieldHtml = this.filter.container.find('[data-purpose="filter-field"][data-key="'+this.getKey()+'"]');
        this.inputs = this.fieldHtml.find('input[type="hidden"]');
    },
    addDOMEvents: function() {
        var _self = this;
        
        $(document).on('shoppingstreet.filter_slider_change', function(e){
            if ($(e.target).data('key') == _self.getKey()) {
                _self.setState();
            }
        });

        $(document).on('shoppingstreet.filter_state_changed', function(e, data){
            if (data.key == _self.getKey()) {
                _self.compareState(data.values);
            }
        });

        $(document).on('shoppingstreet.filter_initialized', function(e){
            _self.setState(true);
        });

        $(document).on('shoppingstreet.filter_reset', function(e){
            _self.setChangedFieldValues([]);
        });
    },
    setState: function(initialize) {
        var values = [];
        var isRange = 0;
        if (this.inputs.length > 0) {
            this.inputs.each(function(){
                values.push($(this).val());
                if ($(this).val() == $(this).data('range-value')) {
                    isRange++;
                }
            });
        }

        if (isRange == values.length) {
            values = [];
        }

        this.state = values;
        
        if (!initialize) {
            this.filter.updateFieldValues(this.getKey(), this.serialize());
        } else if (this.state.length > 0) {
            this.filter.setFieldValues(this.getKey(), this.serialize());
        }
    },
    setChangedFieldValues: function(values) {
        if (values.length == 0) {
            var slider = this.fieldHtml.find('.slider[data-key="'+this.getKey()+'"]').get(0);
            if (slider) {
                var min = this.fieldHtml.find('input[type="hidden"][data-purpose="min"][data-key="'+this.getKey()+'"]');
                var max = this.fieldHtml.find('input[type="hidden"][data-purpose="max"][data-key="'+this.getKey()+'"]');

                slider.noUiSlider.updateOptions({
                    start: [(min.length > 0 ? min.data('range-value') : 0), max.length > 0 ? max.data('range-value') : 0]
                });
            }
        }

        this.state = values;
    }
});
