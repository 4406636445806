(function($) {
    var ProductFilter = function(elem, options) {
        try {
            if (!elem) {
                throw 'Container is required';
            }
            this.container = $(elem);
            if (options) {
                this.setParams(options);
            }

            this.init();
        } catch (error) {
            console.error(error);
        }
    };

    ProductFilter.prototype = {
        container: undefined,
        form: undefined,
        options: {
            ajax: false,
            refreshOnChange: false,
            refreshOnScroll: false,
            ajaxPageLimit: 0,
            debug: false,
            orderings: {},
            fields: {},
            displayMode: 1,
            products: [],
            total: 0,
            searchParam: false,
            num_pages: 0,
            per_page: 20,
            mobileView: false,
            mobileViewBreakpoint: 0,
            hasMobileFilterLoader: false,
            language: undefined,
            scrollRefreshSensMultiplier: 0.8,
            hasProductQuantityNotification: false,
            searchInputName: 'search',
            mobileBtnChangeType: 'display',
        },
        texts: {},
        products: [],
        pagerOptions: {},
        pager: undefined,
        pagerArea: undefined,
        counterArea: undefined,
        productListContainer:undefined,
        current_page: 1,
        sendedForm: false,
        orderInput: undefined,
        fieldInstances: {},
        fieldValues: {},
        tagger: undefined,
        resetButton: undefined,
        orderInput: undefined,
        mobileFilterButton: undefined,
        filterContainer: undefined,
        filterCloseButton: undefined,
        layoutButtons: undefined,
        opened_filter: false,
        openableListItems: {},
        draw_with_js: true,
        filterFieldInit: function(field){ 
            return null;
        },
        handleViewChange: function(layout, resize) {

        },
        init: function() {
            this.setElements();
            this.checkFilterFieldContentHeights();
            this.drawProducts();
            this.addDOMEvents();
            $(document).trigger('shoppingstreet.filter_initialized');
            
            this.checkResetButtonVisibility();
        },
        setElements: function() {
            var _self = this;

            this.form = this.container.find('form');
            if (!this.form) {
                throw 'Form is required';
            }

            if (!this.getOption('refreshOnScroll')) {
                this.pagerArea = this.container.find('[data-purpose="pager-area"]');
                if (this.getOption('hasPager') && this.pagerArea.length > 0) {
                    this.pager = new ProductFilterPager(this, this.pagerOptions);
                }
            }
            
            this.orderInput = this.container.find('[data-purpose="order-select"]');
            
            if(this.getOption('hasTags')) {
                this.tagger = new FilterTagger(this);
            }
            
            this.filterContainer = this.container.find('[data-purpose="filter-fields-container"]')
            this.productListContainer = this.container.find('[data-purpose="product-list"]');
            this.resetButton = this.container.find('[data-purpose="filter-reset-button"]');
            this.counterArea = this.container.find('[data-purpose="filter-counter"]');
            this.orderInput = this.container.find('[data-purpose="order"]');
            this.mobileFilterButton = this.container.find('[data-purpose="mobile-filter-btn"]');
            this.filterCloseButton = this.container.find('[data-purpose="filter-close-button"]');
            this.layoutButtons = this.container.find('[data-purpose="layout-change-button"]');

            this.container.find('.openable-list').each(function(){
                var key = $(this).data('key');
                if (typeof _self.openableListItems[key] === 'undefined') {
                    _self.openableListItems[key] = $(this);
                }
            });
        },
        addDOMEvents: function() {
            var _self = this;
            
            this.container.find('[data-purpose="checkbox-list-button"]').click(function() {
                var key = $(this).data('key');
                
                var list = _self.container.find('[data-purpose="checkbox-list"][data-key="'+key+'"]');
                if (list.length > 0) {
                    if ($(this).data('type') == 'open') {
                        $(this).hide();
                        var closer = $(this).parent().find('[data-purpose="checkbox-list-button"][data-type="close"]');
                        if (closer.length > 0) {
                            closer.show();
                        }
                        _self.openFilterFieldContent(list);
                    } else {
                        $(this).hide();
                        var opener = $(this).parent().find('[data-purpose="checkbox-list-button"][data-type="open"]');
                        if (opener.length > 0) {
                            opener.show();
                        }
                        _self.closeFilterFieldContent(list);
                    }
                }
            });

            if (this.orderInput.length > 0) {
                this.orderInput.change(function() {
                    _self.setCurrentPage(1);
                    _self.submitForm();
                });
            }

            $(document).on('shoppingstreet.accordionitemopen', function(e, list){
                var key = $(list).data('key');
                var listItem = key && typeof _self.openableListItems[key] !== 'undefined' ? _self.openableListItems[key] : undefined;
                if (listItem) {
                    _self.setFilterFieldContentHeights(listItem);
                }
            });

            $(document).on('shoppingstreet.pager_value_changed', function(e, page){
                _self.setCurrentPage(page);
                _self.getPage();
            });

            if (this.resetButton.length > 0) {
                this.resetButton.click(function(){
                    _self.resetFilter();
                });
            }

            if (this.mobileFilterButton) {
                this.mobileFilterButton.click(function(){
                    _self.changeFilterVisibility();
                });
            }

            if (this.filterCloseButton) {
                this.filterCloseButton.click(function(){
                    _self.changeFilterVisibility();
                });
            }

            
            if (this.getOption('refreshOnScroll')) {
                var scrollEvt = function() {
                    var currentPage = _self.getCurrentPage();
                    var limit = _self.getOption('ajaxPageLimit');
                    var load = limit ? currentPage <= limit : true;
                    
                    if (load && _self.getCurrentPage() < _self.options.num_pages && !_self.sendedForm) {
                        var scrollTop = $(window).scrollTop();
                        var winHeight = $(window).height();
                        
                        var areaTop = _self.productListContainer.offset().top;
                        var areaHeight = _self.productListContainer.height();
                        var areaBottom = areaTop + areaHeight;
                        var multiplier = parseFloat(_self.getOption('scrollRefreshSensMultiplier'));
                        if (isNaN(multiplier)) {
                            multiplier = 1;
                        }

                        if ((areaBottom * multiplier) <= (scrollTop + winHeight)) {
                            currentPage++;
                            _self.setCurrentPage(currentPage);
                            _self.getPage();
                        }
                    }
                };

                $(window).on('scroll', scrollEvt);
            }
            
            if (this.getOption('mobileViewBreakpoint')) {
                var breakpoint = parseInt(this.getOption('mobileViewBreakpoint'));
                if (breakpoint > 0) {
                    if (typeof windowWidth != 'undefined' && windowWidth <= breakpoint) {
                        this.changeMobileView(true);
                    }

                    $(document).on('shoppingstreet.window_width_changed', function(e, newWidth) {
                        if (!_self.isMobileView() && newWidth <= breakpoint) {
                            _self.changeMobileView(true);
                        } else if (_self.isMobileView() && newWidth > breakpoint) {
                            _self.changeMobileView(false);
                        }

                        _self.handleViewChange(_self.options.displayMode, true);
                    });
                }
            }

            if (this.layoutButtons && this.layoutButtons.length > 0) {
                this.layoutButtons.click(function(){
                    if (!$(this).hasClass('active')) {
                        _self.layoutButtons.removeClass('active');
                        $(this).addClass('active');
                        var layout = $(this).data('view');

                        _self.saveFilterLayout(layout);
                        _self.options.displayMode = layout;
                        _self.handleViewChange(layout);
                    }
                });
            }

            if (this.mobileFilterButton && this.mobileFilterButton.length > 0) {
                var scrollEvent = function() {
                    var top = _self.mobileFilterButton.parent().offset().top;
                    var borderLine = _self.productListContainer.offset().top + _self.productListContainer.outerHeight(true);
                    if ((_self.getCurrentPage() >= _self.options.num_pages || !_self.getOption('refreshOnScroll')) && top >= borderLine) {
                        _self.mobileFilterButton.addClass('inactive');
                    } else if (_self.mobileFilterButton.hasClass('inactive') && (top < borderLine)) {
                        _self.mobileFilterButton.removeClass('inactive');
                    }
                };

                window.addEventListener('scroll', scrollEvent);
            }
            
        },
        addProductDOMEvents: function() {
            var _self = this;
            if (typeof webshopUtils !== 'undefined') {
                this.productListContainer.find('[data-purpose="cart-button"]:not(.initialized)').click(function(e){
                    e.preventDefault();
                    
                    var variationId = $(this).data('id');
                    if (variationId) {
                        var product = typeof _self.products[variationId] !== 'undefined' ? _self.products[variationId] : undefined;
                        if (product) {
                            var variationData = {
                                id: variationId,
                                name: product.name,
                                link: product.link,
                                reference_id: product.reference_id,
                                unit: typeof product.unit !== 'undefined' ? product.unit : ''
                            };
                            var productData = {
                                id: product.id,
                                unit: typeof product.unit !== 'undefined' ? product.unit : ''
                            }

                            webshopUtils.addToCart(variationId, 1, variationData, productData);
                        }
                    }
                });

                this.productListContainer.find('[data-purpose="notice-button"]:not(.initialized)').click(function(e){
                    e.preventDefault();

                    var variationId = $(this).data('id');
                    if (variationId) {
                        $(document).trigger('shoppingstreet.open_product_notice_remodal', variationId);
                    }
                });

                this.productListContainer.find('[data-purpose="cart-button"]').addClass('initialized');
                this.productListContainer.find('[data-purpose="notice-button"]').addClass('initialized');
            }
        },
        setParams: function(params) {
            var deletableKeys = ['texts', 'products', 'pagerOptions', 'fields', 'filterFieldInit', 'current_page', 'drawWithJs', 'handleViewChange', 'drawNoResultContent', 'drawProducts'];
            if (typeof params.drawProducts === 'function') this.drawProducts = params.drawProducts;
            if (params.texts) this.setTexts(params.texts);
            if (typeof params.drawWithJs !== 'undefined') this.setDrawWithJs(params.drawWithJs);
            if (!this.getDrawWithJs()) {
                this.products = {};
            }
            if (params.products) this.setProducts(params.products);
            if (params.pagerOptions) this.setPagerOptions(params.pagerOptions);
            if (typeof params.filterFieldInit === 'function') this.filterFieldInit = params.filterFieldInit;
            if (typeof params.createUrl === 'function') this.createUrl = params.createUrl;
            if (typeof params.drawNoResultContent === 'function') this.drawNoResultContent = params.drawNoResultContent;
            if (typeof params.handleViewChange === 'function') this.handleViewChange = params.handleViewChange;
            if (params.fields) this.setFilterFields(params.fields);
            if (params.current_page) this.setCurrentPage(params.current_page)
            
            deletableKeys.forEach(function(item){
                if (typeof params[item] !== 'undefined') {
                    delete params[item];
                }
            });
            
            if (params) {
                this.options = $.extend({}, this.options, params);
            }
        },
        setFilterFields: function(fields) {
            if (fields.length > 0) {
                for (var i = 0; i < fields.length; i++) {
                    if ((!fields[i].skip_init || fields[i].force_js_init) && $('[data-purpose="filter-field"][data-key="'+fields[i].key+'"]').length > 0) {
                        var obj = this.getFilterFieldInstance(fields[i]);
                        if (obj) {
                            this.fieldInstances[obj.getKey()] = obj;
                        }
                    }
                }
            }
        },
        getFilterFieldInstance: function(field) {
            var obj = this.filterFieldInit(field);
            if (!obj) {
                fieldObj = null;
                switch (field.operator) {
                    case 'between':
                        obj = new SliderFilterField(this, field);
                        break;
                    case 'in':
                        obj = new CheckboxFilterField(this, field);
                }
            }

            return obj;
        },
        updateFieldValues: function(key, datas) {
            this.fieldValues[key] = datas;
            $(document).trigger('shoppingstreet.filter_state_changed', this.fieldValues[key]);
            this.checkResetButtonVisibility();
            if (this.getOption('refreshOnChange')) {
                this.setCurrentPage(1);
                this.submitForm();
            }
        },
        setFieldValues: function(key, datas) {
            this.fieldValues[key] = datas;
            $(document).trigger('shoppingstreet.filter_state_changed', this.fieldValues[key]);
        },
        checkResetButtonVisibility: function() {
            if (typeof this.resetButton !== 'undefined' && this.resetButton.length > 0) {
                if (typeof this.fieldValues === 'object' && Object.keys(this.fieldValues).length > 0) {
                    var hasValue = false;
                    var keys = Object.keys(this.fieldValues);
                    for (var i = 0; i < keys.length; i++) {
                        var field = this.fieldValues[keys[i]];
                        if (typeof field.values !== 'undefined') {
                            if (Array.isArray(field.values) && field.values.length > 0) {
                                hasValue = true;
                            } else if (!Array.isArray(field.values) && field.values) {
                                hasValue = true;
                            }

                            if (hasValue) {
                                break;
                            }
                        }
                    }
                    
                    if (hasValue && !this.resetButton.hasClass('active')) {
                        this.resetButton.addClass('active');
                    } else if (!hasValue && this.resetButton.hasClass('active')) {
                        this.resetButton.removeClass('active');
                    }
                } else {
                    this.resetButton.removeClass('active');
                }
            }
        },
        getFieldValue: function(key) {
            if (typeof this.fieldValues[key] === 'object') {
                return this.fieldValues[key];
            }

            return null;
        },
        setTexts: function(texts) {
            this.texts = $.extend({}, this.texts, texts);
        },
        setProducts: function(products) {
            if (typeof products == 'string') {
                products = JSON.parse(products);
            }
            
            if (products.length > 0) {
                for (var i = 0; i < products.length; i++) {
                    this.addProduct(products[i]);
                }
            }
        },
        addProduct: function(product) {
            if (this.getDrawWithJs()) {
                var obj = new Product(this, product);
                this.products.push(obj);
            } else {
                this.products[product.variation_id] = product;
            }
        },
        drawProducts: function() {
            if (!this.getOption('refreshOnScroll') && this.getDrawWithJs()) {
                this.productListContainer.html('');
            }
            var length = this.getDrawWithJs() ? this.products.length : Object.keys(this.products).length;
            if (length > 0) {
                if (this.getDrawWithJs()) {
                    var start = 0;
                    if (this.getOption('refreshOnScroll')) {
                        var perPage = parseInt(this.getOption('per_page'));
                        if (isNaN(perPage)) {
                            perPage = 0;
                        }
                        start = (this.getCurrentPage() - 1) * perPage;
                    }

                    for (var i = start; i < length; i++) {
                        this.productListContainer.append(this.products[i].render(this.getOption('displayMode')));
                    }
                } else {
                    this.addProductDOMEvents();
                }
            } else if (length == 0) {
                this.drawNoResultContent();
            }
        },
        setPagerOptions: function(options) {
            this.pagerOptions = $.extend({}, this.pagerOptions, options);
        },
        setCurrentPage: function(page) {
            this.current_page = page;
        },
        setDrawWithJs: function(draw_with_js) {
            this.draw_with_js = draw_with_js;
        },
        getDrawWithJs: function() {
            return this.draw_with_js;
        },
        getOption: function(key) {
            return typeof this.options[key] !== 'undefined' ? this.options[key] : null;
        },
        getCurrentPage: function() {
            return this.current_page;
        },
        getPage: function() {
            if (this.getOption('ajax')) {
                this.loadDataFromUrl(base_url + 'categorycontroller/filterpage', {
                    page:this.getCurrentPage()
                }, this.createUrl());
            } else {
                this.submitForm();
            }
            
        },
        submitForm: function() {
            var url = this.createUrl();
            if (this.getOption('ajax')) {
                this.loadDataFromUrl(url);
            } else {
                document.location.href = url;
            }
        },
        loadDataFromUrl: function(url, data, refreshUrl) {
            var _self = this;
            if (this.sendedForm) {
               return; 
            }
            
            this.sendedForm = true;
            if (!data) {
                data = {};
            }

            data.lang = this.getSiteLang();
            var filterClass = !this.opened_filter ? 'mobile-filter-open' : 'mobile-filter-close';
            $.ajax({
                url:url,
                dataType:'json',
                type:'post',
                data:data,
                beforeSend: function() {
                    var newUrl = typeof refreshUrl === 'string' ? refreshUrl : url;
                    window.history.pushState({
                        path:newUrl
                    }, '', newUrl);

                    if (!_self.isMobileView()) {
                        _self.productListContainer.addClass('loading');
                    } else if (_self.getOption('hasMobileFilterLoader')) {
                        _self.changeMobileBtnContent('mobile-filter-loader');
                    }
                },
                success: function(response) {
                    if (typeof response.options !== 'undefined') {
                        _self.options = $.extend({}, _self.options, response.options);
                    }
                    
                    _self.destroy();

                    if (typeof response.items !== 'undefined' && response.items.length > 0) {
                        _self.setProducts(response.items);
                        if (!_self.getDrawWithJs() && typeof response.product_html !== 'undefined') {
                            if (_self.getOption('refreshOnScroll') && _self.getCurrentPage() > 1) {
                                _self.productListContainer.append(response.product_html);
                            } else {
                                _self.productListContainer.html(response.product_html);
                            }
                        }
                    } 
                    
                    _self.drawProducts(0);

                    if (_self.counterArea.length > 0) {
                        _self.counterArea.html(webshopUtils.numberFormat(parseInt(_self.getOption('total')), true));
                    }
                        
                    _self.sendedForm = false;
                    
                    if (!_self.isMobileView()) {
                        _self.productListContainer.removeClass('loading');
                    } else if (_self.getOption('hasMobileFilterLoader')) {
                        _self.changeMobileBtnContent(filterClass);
                    }

                    $(document).trigger('shoppingstreet.category_page_content_changed');
                }
            });
        },
        createUrl: function() {
            var url = this.getOption('base_url');
            var keys = Object.keys(this.fieldInstances);
            var isUrlFriendly = this.getOption('url_model') == 'url_friendly';
            var params_url = '';
            var lang = this.getSiteLang();
            var params = [];
            var separator = isUrlFriendly ? '/' : '&';
            var value_separator = isUrlFriendly ? '/' : '=';
            var isSearch = this.getOption('searchParam') ? true : false;
            
            if (!isUrlFriendly && isSearch) {
                params.push(this.getOption('searchInputName')+'='+this.getOption('searchParam'));
            }
                        
            if (keys.length > 0) {
                for (var i = 0; i < keys.length; i++) {
                    var fieldValue = this.getFieldValue(keys[i]);
                    if (fieldValue && Array.isArray(fieldValue.values)) {
                        var slug = typeof fieldValue.slug[lang] !== 'undefined' ? fieldValue.slug[lang] : undefined;
                        if (slug) {
                            if (isUrlFriendly) {
                                var items_separator = typeof fieldValue.separator !== 'undefined' ? fieldValue.separator : undefined;
                                if (!items_separator) {
                                    items_separator = this.getOption('multipleSeparator');
                                }
                                var values = fieldValue.values.join(items_separator);
                                if (values) {
                                    params.push(slug + value_separator + values);
                                }
                            } else {
                                for (var index in fieldValue.values) {
                                    var value = fieldValue.values[index];
                                    switch (fieldValue.type) {
                                        case 'checkbox':
                                        case 'slider':
                                            var indexStr = '';
                                            if (fieldValue.type == 'slider') {
                                                indexStr = index;
                                            }
                                            params.push(slug + '['+indexStr+']' + value_separator + value);
                                            break;
                                        default:
                                            params.push(slug + value_separator + value);
                                            break;
                                    }
                                }
                            }
                        }
                    }
                }                
            }

            var slugs = this.getOption('slugs');
            var current_page = this.getCurrentPage();
            
            if (current_page && current_page > 1 && !this.getOption('refreshOnScroll')) {
                if (typeof slugs.page !== 'undefined' && typeof slugs.page[lang] !== 'undefined') {
                    params.push(slugs.page[lang]+value_separator+current_page);
                }
            }

            if (this.orderInput.length > 0 && typeof this.options.slugs !== 'undefined' && typeof this.options.slugs.order !== 'undefined' && typeof this.options.slugs.order[lang] !== 'undefined') {
                var order = this.orderInput.val();
                var tmp = order.split('-');
                
                var ordering = typeof this.options.orderings[tmp[0]] !== 'undefined' ? this.options.orderings[tmp[0]] : undefined;
                if (ordering) {
                    if (!(ordering.default_type && ordering.default == tmp[1])) {
                        if (typeof ordering.slug[lang] !== 'undefined') {
                            params.push(this.options.slugs.order[lang] + value_separator + ordering.slug[lang]+'-'+tmp[1]);
                        }
                    }
                }
            }

            params_url = params.join(separator);
            if (isUrlFriendly) {
                params_url = separator + params_url;
                
                if (isSearch) {
                    params_url = params_url + (url.indexOf('?') > -1 ? '&' : '?') + this.getOption('searchInputName')+'='+this.getOption('searchParam');
                }
            } else {
                params_url = (url.indexOf('?') > -1 ? '&' : '?') + params_url;
            }
            
            return url + params_url;
        },
        checkFilterFieldContentHeights: function() {
            var keys = Object.keys(this.openableListItems);
            if (keys.length > 0) {
                for (var i = 0; i < keys.length; i++) {
                    this.setFilterFieldContentHeights(this.openableListItems[keys[i]]);
                }
            }
        },
        setFilterFieldContentHeights: function(list) {
            var height = 0;
            
            list.children().each(function(){
                if (!$(this).hasClass('hidden')) {
                    height += $(this).outerHeight(true);
                }
            });
            
            list.height(height);
        },
        openFilterFieldContent: function(list) {
            list.find('.hidden').removeClass('hidden');
            this.setFilterFieldContentHeights(list);
        },
        closeFilterFieldContent: function(list){
            var limit = list.data('limit');
            if (!isNaN(limit) && limit > 0) {
                list.children(':gt('+(limit - 1)+')').addClass('hidden');
                this.setFilterFieldContentHeights(list);
            }
        },
        resetFilter: function() {
            this.fieldValues = {};
            this.submitForm();
            this.resetButton.removeClass('active');
            $(document).trigger('shoppingstreet.filter_reset');
        },
        getSiteLang: function() {
            var lang = this.getOption('language');
            if (!lang && typeof current_lang !== 'undefined') {
                lang = current_lang;
            }

            if (!lang) {
                lang = 'hu';
            }

            return lang;
        },
        drawNoResultContent: function() {
            this.productListContainer.html();
        },
        getText: function(key) {
            return typeof this.texts[key] !== 'undefined' ? this.texts[key] : key;
        },
        destroy: function() {
            if (!this.getOption('refreshOnScroll') || this.getCurrentPage() == 1) {
                $(window).scrollTop(0);
                if (this.getDrawWithJs()) {
                    this.productListContainer.html('');
                    this.products = [];
                } else {
                    this.products = {};
                    this.productListContainer.find('[data-purpose="cart-button"]').off('click');
                }
            }
        },
        changeFilterVisibility: function() {
            if (this.filterContainer.length > 0) {
                var _self = this;
                var buttonType = 'mobile-filter-open';
                if (!this.opened_filter) {
                    $('body, html').addClass('noscroll');
                    this.filterContainer.addClass('opened');
                    this.opened_filter = true;
                    buttonType = 'mobile-filter-close';
                    setTimeout(function(){
                        $('.wrapper').click(function(e){
                            if (e.pageX > _self.filterContainer.outerWidth()) {
                                _self.changeFilterVisibility();
                            }
                        });
                    }, 50);
                } else {
                    $('body, html').removeClass('noscroll');
                    this.filterContainer.removeClass('opened');
                    this.opened_filter = false;
                    $('.wrapper').off('click');
                }

                this.changeMobileBtnContent(buttonType);
            }
        },
        changeMobileBtnContent: function(type) {
            switch (this.getOption('mobileBtnChangeType')) {
                case 'display':
                    this.mobileFilterButton.find('.group').hide();
                    this.mobileFilterButton.find('[data-purpose="' + type + '"]').show();
                    break;
                case 'class':
                    this.mobileFilterButton.find('.group').removeClass('active');
                    this.mobileFilterButton.find('[data-purpose="' + type + '"]').addClass('active');
                    break;
            }
        }, 
        changeMobileView: function(open){
            this.options.mobileView = open;
            this.changeMobileBtnContent('mobile-filter-open');
            if (!open) {
                this.filterContainer.removeClass('opened');
                $('body, html').removeClass('noscroll');
            }
        },
        isMobileView: function() {
            return this.getOption('mobileView');
        },
        saveFilterLayout: function(layout) {
            $.ajax({
                url:ajax_controller + '/set_filter_layout/'+layout,
                type:'get',
                dataType:'json',
                complete: function(rData) {
                    
                }
            });
        }
    };

    $.fn.productFilter = function(options) {
        return this.each(function() {
            new ProductFilter($(this), options);
        });
    };

})(jQuery);
