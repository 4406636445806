/* Credits: http://paulmolluzzo.github.io/simple_share_popups/ 
	http://www.xtf.dk/
*/
(function($) {

	var SocialShare = function($element, options){
		this.element = $element;

		this.defaults = {
			width: 500,
			height: 300
		};
		this.options = $.extend({}, this.defaults, options);

		this.init();

	};

	SocialShare.prototype = {

		init: function() {
			var _self = this;

			_self.element.find('a').each(function(ind, el) {
				$(el).click(function(e) {
					e.preventDefault();

					var dualScreenLeft = window.screenLeft != undefined ? window.screenLeft : screen.left;
				    var dualScreenTop = window.screenTop != undefined ? window.screenTop : screen.top;

				    var width = window.innerWidth ? window.innerWidth : document.documentElement.clientWidth ? document.documentElement.clientWidth : screen.width;
				    var height = window.innerHeight ? window.innerHeight : document.documentElement.clientHeight ? document.documentElement.clientHeight : screen.height;

				    var left = (width / 2 - (_self.options.width / 2)) + dualScreenLeft;
				    var top = (height / 2 - (_self.options.height / 2)) + dualScreenTop;
				    var newWindow = window.open(this.href, this.title, 'scrollbars=1, width=' + _self.options.width + ', height=' + _self.options.height + ', top=' + top + ', left=' + left);

				    // Puts focus on the newWindow
				    if (window.focus) {
				        newWindow.focus();
				    }
				});
			});

		}
	};

    $.fn.socialShare = function(options) {
        return this.each(function() {
            new SocialShare($(this), options);
        });
    };
})(jQuery);
