function FilterField(filter, params) {
    this.filter = filter;
    if (params) {
        this.setParams(params);
    }
    this.init();
}

FilterField.prototype = {
    filter: undefined,
    key: undefined,
    slug: undefined,
    type: undefined,
    inputs: [],
    state: [],
    init: function() {
        this.setElements();
        this.addDOMEvents();
    }, 
    setElements: function() {
        this.fieldHtml = this.filter.container.find('[data-purpose="filter-field"][data-key="'+this.getKey()+'"]');
        this.inputs = this.fieldHtml.find('input');
    },
    addDOMEvents: function() {
        var _self = this;

        if (this.inputs.length > 0) {
            this.inputs.change(function(){
                _self.setState();
            });
        }

        $(document).on('shoppingstreet.filter_state_changed', function(e, data){
            if (data.key == _self.getKey()) {
                _self.compareState(data.values);
            }
        });

        $(document).on('shoppingstreet.filter_initialized', function(e){
            _self.setState(true)
        });

        $(document).on('shoppingstreet.filter_reset', function(e){
            _self.setChangedFieldValues([]);
        });
    },
    setParams: function(params) {
        if (params.key) this.setKey(params.key);
        if (params.slug) this.setSlug(params.slug);
        if (params.separator) this.setSeparator(params.separator);
    },
    setKey: function(key) {
        this.key = key;
    },
    setSeparator: function(separator) {
        this.separator = separator;
    },
    setState: function(initialize) {
        var values = [];
        if (this.inputs.length > 0) {
            this.inputs.each(function(){
                values.push($(this).val());
            });
        }

        this.state = values;
        
        if (!initialize) {
            this.filter.updateFieldValues(this.getKey(), this.serialize());
        } else if (this.state.length > 0) {
            this.filter.setFieldValues(this.getKey(), this.serialize());
        }
    },
    setSlug: function(slug) {
        this.slug = slug;
    },
    getSeparator: function() {
        return this.separator;
    },
    compareState: function(values) {
        if (!this.isEqual(values)) {
            this.setChangedFieldValues(values);
        } 
    },
    isEqual: function(values) {
        if (values.length != this.state.length) {
            return false;
        }

        for (var i = 0; i < this.state.length; i++) {
            var val = this.state[i];
            if (values.indexOf(val) < 0) {
                return false;
            }
        }

        for (var i = 0; i < values.length; i++) {
            var val = values[i];
            if (this.state.indexOf(val) < 0) {
                return false;
            }
        }

        return true;
    },
    setChangedFieldValues: function(values) {
        console.log('ezt jelenlegi tudásunk szerint a gyermeknek kell kezelnie');
    },
    getState: function() {
        return this.state;
    },
    getType: function() {
        return this.type;
    },
    getKey: function() {
        return this.key;
    },
    getSlug: function() {
        return this.slug;
    },
    serialize: function() {
        return {
            type: this.getType(),
            key: this.getKey(),
            slug: this.getSlug(),
            values: this.getState(),
            separator: this.getSeparator()
        };
    }
};
